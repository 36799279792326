import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

export default function Subscribe() {
  const [formData, setFormData] = useState({
    email: "",
  });

  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.email) {
      setShowPopup(true);
      setFormData({
        email: "",
      });
    }
  };


  return (
    <main className="main backShare">
     <Helmet>
        <title>Subscribe - PoppyGame</title>
      </Helmet>
      <br />
      <br />
      <div className="container">
        <section
          id="contact"
          className="contact section contact_back section_top"
        >
           <img
              className="img-head"
              src="https://raw.githubusercontent.com/emnatkins/cdn-codepen/main/LYJWgdK/LYJWgdK.mail.jpg"
              alt="subscribe to email"
            />
          <div className="container section-title" data-aos="fade-up">
            <h2 className="contact_text_color">subscribe</h2>
            <p> subscribe to our newsletter & stay updated</p>
          </div>
          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row gy-4">
            {showPopup && (
              <div className="popup all_text_center">
                <p className="design_p_color">
                  Thank you for subscribing! You will receive updates soon.
                  <i
                    className="fa fa-times msg_border"
                    onClick={() => setShowPopup(false)}
                    aria-hidden="true"
                  ></i>
                </p>
              </div>
            )}
            <form onSubmit={handleSubmit} id="commentForm">
              <div className="form-box_2">
                <div className="input-main">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-envelope"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                  </svg>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address*"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <button className="submit submit_c" type="submit">
                  Submit
                </button>
              </div>
            </form>
            </div>
          </div>
        </section>
        <br />
        <br />
        <br />
      </div>
      <Footer />
    </main>
  );
}
