import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import star from "../images/1.ico";
import "../App.css";

export default function Header() {
  const navigate = useNavigate();
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const searchInput = e.target.elements.search.value;
    navigate(`/search?query=${encodeURIComponent(searchInput)}`);
    setIsSearchOpen(false); // Close the search popup after submitting
  };

  const toggleSearchPopup = () => {
    setIsSearchOpen((prev) => !prev);
  };

  return (
    <header className="header d-flex align-items-center fixed-top">
      <div className="container-fluid container-xl position-relative d-flex align-items-center">
        <div className="logo d-flex align-items-center me-auto">
          <Link to={`/`}>
            <img src={logo} className="gamehubs" alt="Game Hubs Logo" />
          </Link>
          <Link to={`/`} className="hot_">
            <h1 className="btn-getstarted">
              <img src={star} className="hot" alt="Hot" />
              Hot
            </h1>
          </Link>
        </div>
      </div>
      <div className="search_pat d-none d-md-block">
          <form onSubmit={handleSearchSubmit}>
            <input type="text" placeholder="Search" name="search" />
            <button type="submit">
              <i className="fa fa-search" aria-hidden="true"></i>
            </button>
          </form>
        </div>
        <div className="search-icon-mobile d-md-none" onClick={toggleSearchPopup}>
          <i className="fa fa-search icon_search" aria-hidden="true"></i>
        </div>
        {isSearchOpen && (
          <div className="search_pat mobile-popup">
            <form onSubmit={handleSearchSubmit}>
              <input type="text" placeholder="Search" name="search" />
              <button type="submit">
                <i className="fa fa-search " aria-hidden="true"></i>
              </button>
            </form>
          </div>
        )}
    </header>
  );
}