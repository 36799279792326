import React, { useState, useEffect } from "react";
import close_icon from "../images/close_icon.svg";
import AdsenseContent from "./AdsenseContent";
import SkeletonLoader from "./SkeletonLoader";

const ClickCounter = ({ children, setPopupVisible }) => {
  const [clickCount, setClickCount] = useState(0);
  const [adLoaded, setAdLoaded] = useState(false);

  useEffect(() => {
    const adLoadingTimeout = setTimeout(() => {
      setAdLoaded(true);
    }, 2000);

    return () => clearTimeout(adLoadingTimeout);
  }, []);

  const handleClick = () => {
    setClickCount((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount === 6) {
        setPopupVisible(true);
      }
      return newCount;
    });
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
    setClickCount(0);
  };

  return (
    <div onClick={handleClick}>
      {children}
      {clickCount >= 6 && (
        <div className="popup-overlay_pop">
          <div className="popup-content_pop">
            <div className="add_shows">
              <p className="Advertisement">Sponsored</p>
              {!adLoaded ? (
                <SkeletonLoader />
              ) : (
                <AdsenseContent
                  adUnit="/23178317433/9GH_display_1"
                  name="div-gpt-ad-1728898170537-0" 
                  size={[[336, 280], [320, 50], [300, 50], [250, 250], [300, 250]]}
                />
              )}
            </div>
            <button className="add_button" onClick={handleClosePopup}>
              <img src={close_icon} alt="Close" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClickCounter;
