import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import appstore from "../images/about.jpg";
import emoji1 from "../images/emoji1.png";

export default function AboutUs() {
  return (
    <main className="main backShare">
      <Helmet>
        <title>Contact Us - PoppyGame</title>
      </Helmet>
      <br />
      <br />
      <div className="container">
        <section
          id="contact"
          className="contact section contact_back section_top"
        >
          <img
            className="img-head"
            src="https://raw.githubusercontent.com/emnatkins/cdn-codepen/main/LYJWgdK/LYJWgdK.mail.jpg"
            alt="subscribe to email"
          />
          <div className="container section-title" data-aos="fade-up">
            <h2 className="contact_text_color">Contact</h2>
            <p>Contact Us</p>
          </div>
          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row gy-4">
              <div className="info-item" data-aos="fade" data-aos-delay="400">
                <i className="bi bi-envelope"></i>
                <h3 className="form_center">Email Us</h3>
                <a href="mailto:info@poppygame.com">
                  <p className="contact_text_color form_center">
                    info@poppygame.com
                  </p>
                </a>
              </div>
            </div>
          </div>
        </section>
        <br />
        <br />
        <br />
      </div>
      <Footer />
    </main>
  );
}
