import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import gameData from "./Gameapps.json";
import Scrollacktop from "./Scrollacktop";
import AdsenseContent from "./AdsenseContent";
import { Helmet } from "react-helmet";
import lazy from "../images/lazy.png";

export default function Share() {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const itemsPerPage = 30;
  const [adLoaded, setAdLoaded] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    loadMoreItems(0);
    const timer = setTimeout(() => {
      setAdLoaded(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  const loadMoreItems = async (newPage) => {
    setLoading(true);
    setError(null);
    const start = newPage * itemsPerPage;

    try {
      const newItems = gameData.data.game.slice(start, start + itemsPerPage);
      if (newItems.length > 0) {
        setItems((prevItems) => {
          const uniqueItems = [...prevItems, ...newItems].filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.slug === item.slug)
          );
          return uniqueItems;
        });
      } else if (newPage === 0) {
        setError("No items found.");
      }
    } catch (err) {
      setError("An error occurred while loading items.");
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMore = async () => {
    if (loading || loadingMore) return;
    setLoadingMore(true);
    const newPage = page + 1;
    setPage(newPage);

    await new Promise((resolve) => setTimeout(resolve, 500));
    await loadMoreItems(newPage);
    setLoadingMore(false);
  };

  return (
    <main className="main backShare">
      <Helmet>
        <title>
          Top Game and App Reviews | Expert Reviews and Introductions
        </title>
      </Helmet>
      <br />
      <section className="portfolio games_list">
        <div className="container-fluid">
          <div className="row gy-4 share_section">
            <div className="col-lg-3">
              {error && <div className="error-message">{error}</div>}
              {items.length === 0 && !loading && (
                <div>No items to display.</div>
              )}
              <div className="add_shows">
                <p className="Advertisement_main">Sponsored</p>
                  <AdsenseContent
                    adUnit="/23178317433/9GH_display_6"
                    name="div-gpt-ad-1728898339799-0"
                    size={[
                      [320, 50],
                      [250, 250],
                      [300, 250],
                      [336, 280],
                      [300, 50],
                    ]}
                  />
              </div>
            </div>
            <div className="col-lg-9">
              <div className="row gy-4 share_section">
                {items.slice(0, 6).map((item) => (
                  <div key={item.slug} className="col-lg-2 portfolio-item">
                    <Link
                      to={`/online-game/${encodeURIComponent(item.slug)}`}
                      className="portfolio-content h-100"
                    >
                      <img
                        src={lazy}
                        data-src={`${process.env.PUBLIC_URL}/games/${item.image}`}
                        className="img-fluid"
                        alt={item.name}
                        loading="lazy"
                        onLoad={(e) => {
                          e.target.src = e.target.dataset.src;
                        }}
                      />
                      <div className="overlayShare_">{item.name}</div>
                    </Link>
                  </div>
                ))}
              </div>
              <div className="row gy-4 share_section">
                {items.slice(6, 12).map((item) => (
                  <div key={item.slug} className="col-lg-2 portfolio-item">
                    <Link
                      to={`/online-game/${encodeURIComponent(item.slug)}`}
                      className="portfolio-content h-100"
                    >
                      <img
                        src={lazy}
                        data-src={`${process.env.PUBLIC_URL}/games/${item.image}`}
                        className="img-fluid"
                        alt={item.name}
                        loading="lazy"
                        onLoad={(e) => {
                          e.target.src = e.target.dataset.src; // Set the actual image source on load
                        }}
                      />
                      <div className="overlayShare_">{item.name}</div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {items.slice(12).map((item) => (
              <div key={item.slug} className="col-lg-2 portfolio-item">
                <Link
                  to={`/online-game/${encodeURIComponent(item.slug)}`}
                  className="portfolio-content h-100"
                >
                  <img
                    src={lazy}
                    data-src={`${process.env.PUBLIC_URL}/games/${item.image}`}
                    className="img-fluid"
                    alt={item.name}
                    loading="lazy"
                    onLoad={(e) => {
                      e.target.src = e.target.dataset.src;
                    }}
                  />
                  <div className="overlayShare">{item.name}</div>
                </Link>
              </div>
            ))}
          </div>
          {!loading && items.length < (gameData.data.game?.length || 0) && (
            <button
              onClick={handleLoadMore}
              className="load-more-button"
              disabled={loadingMore}
            >
              {loadingMore ? "MORE GAMES..." : "MORE GAMES"}
            </button>
          )}
        </div>
      </section>
      <Scrollacktop />
      <br />
      <br />
      <br />
      <Footer />
    </main>
  );
}