import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "./Footer";
import gameData from "./Gameapps.json";
import Scrollacktop from "./Scrollacktop";
import SkeletonLoader from "./SkeletonLoader";
import { Helmet } from "react-helmet";
import lazy from "../images/lazy.png";
import DataNotFound from "../images/Data-Not-Found.jpg";

export default function Search() {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [recentGames, setRecentGames] = useState([]); // State for recent games

  const location = useLocation();
  const query = new URLSearchParams(location.search).get("query") || "";

  useEffect(() => {
    loadItems();
  }, []);

  useEffect(() => {
    filterItems(query);
  }, [query, items]);

  const loadItems = async () => {
    setLoading(true);
    setError(null);
    try {
      const allGameSlugs = gameData.data.game.map((game) => game.slug);
      const gamePromises = allGameSlugs.map(async (slug) => {
        try {
          const response = await import(`./JsonData/${slug}.json`);
          return response.game;
        } catch (err) {
          console.error(`Error loading ${slug}.json:`, err);
          return null;
        }
      });

      const allGames = await Promise.all(gamePromises);
      const validGames = allGames.filter((game) => game);
      setItems(validGames);
      setFilteredItems(validGames);

      // Get the first 10 games for display when no filtered results
      const recentGames = gameData.data.game.slice(0, 10);
      setRecentGames(recentGames);
    } catch (err) {
      setError("An error occurred while loading items.");
    } finally {
      setLoading(false);
    }
  };

  const filterItems = (query) => {
    const lowercasedQuery = query.toLowerCase();
    const filtered = items.filter((item) => {
      const descriptionText = item.Description.map((desc) => desc.p)
        .join(" ")
        .toLowerCase();
      return (
        item.name.toLowerCase().includes(lowercasedQuery) ||
        item.Developer.toLowerCase().includes(lowercasedQuery) ||
        item.OS.toLowerCase().includes(lowercasedQuery) ||
        descriptionText.includes(lowercasedQuery)
      );
    });
    setFilteredItems(lowercasedQuery ? filtered : items);
  };

  return (
    <main className="main backShare">
      <Helmet>
        <title>
          Top Game and App Reviews | Expert Reviews and Introductions
        </title>
      </Helmet>
      <br />
      <section className="portfolio games_list">
        <div className="container-fluid">
          <div className="row gy-4 share_section">
            <div className="col-lg-12">
              <div className="row gy-4 share_section">
                {loading && <SkeletonLoader />}
                {error && <div className="error-message">{error}</div>}
                {!loading && filteredItems.length === 0 && (
                  <div className="form_center">
                    <img
                      src={DataNotFound}
                      className="not_found"
                      alt="Data Not Found"
                    /><br/>
                     <br/>
                     <h3 class="other_online_game">Other Online Games</h3><br/>
                    <div className="row gy-4 otherGames">
                      {recentGames.map((game) => (
                        <div
                          key={game.slug}
                          className="col-lg-2 portfolio-item"
                        >
                          <Link
                            to={`/online-game/${encodeURIComponent(game.slug)}`}
                            className="portfolio-content h-100"
                          >
                            <img
                              src={lazy}
                              data-src={`${process.env.PUBLIC_URL}/games/${game.image}`}
                              className="img-fluid"
                              alt={game.name}
                              loading="lazy"
                              onLoad={(e) => {
                                e.target.src = e.target.dataset.src;
                              }}
                            />
                            <div className="overlayShare">{game.name}</div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {filteredItems.map((item) => (
                  <div key={item.slug} className="col-lg-2 portfolio-item">
                    <Link
                      to={`/online-game/${encodeURIComponent(item.slug)}`}
                      className="portfolio-content h-100"
                    >
                      <img
                        src={lazy}
                        data-src={`${process.env.PUBLIC_URL}/games/images/${item.image}`}
                        className="img-fluid"
                        alt={item.name}
                        loading="lazy"
                        onLoad={(e) => {
                          e.target.src = e.target.dataset.src;
                        }}
                      />
                      <div className="overlayShare">{item.name}</div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Scrollacktop />
      <Footer />
    </main>
  );
}
