import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Share from "./components/Share";
import Privacy from "./components/Privacy";
import Term from "./components/Term";
import AboutUs from "./components/AboutUs";
import Contact from "./components/Contact";
import Subscribe from "./components/Subscribe";
import GameDetail from "./components/GameDetail";
import Search from "./components/Search";
import ClickCounter from "./components/ClickCounter";
import "./App.css";
import { useDetectAdBlock } from "adblock-detect-react";
import adblock from "./images/adblock.png";

function App() {
  const [isClickCounterPopupVisible, setClickCounterPopupVisible] = useState(false);
  const adBlockDetected = useDetectAdBlock();
  const isAnyPopupVisible = isClickCounterPopupVisible;

  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
      window.googletag = window.googletag || {cmd: []};
      googletag.cmd.push(function() {
        var slot = googletag.defineOutOfPageSlot('/23178317433/9GH_Interstitial', googletag.enums.OutOfPageFormat.INTERSTITIAL);
        if (slot) slot.addService(googletag.pubads());
        googletag.enableServices();
        googletag.display(slot);
      });
    `;
    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (process.env.NODE_ENV === 'production') {
    const originalError = console.error;
    console.error = (...args) => {
      if (args[0] && typeof args[0] === 'string') {
        if (args[0].includes('ignore error...')) {
          return; 
        }
      }
      originalError(...args); 
    };
  }

  return (
    <ClickCounter setPopupVisible={setClickCounterPopupVisible}>
      <Router>
        <Header />
        <div className={isAnyPopupVisible ? "blurred" : ""}>
          <Routes>
            <Route path="/" element={<Share />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="term" element={<Term />} />
            <Route path="aboutUs" element={<AboutUs />} />
            <Route path="contact" element={<Contact />} />
            <Route path="subscribe" element={<Subscribe />} />
            <Route path="/online-game/:gameDetail" element={<GameDetail />} />
            <Route path="/search" element={<Search />} />
          </Routes>
          {isClickCounterPopupVisible && (
            <div className="click-counter-popup">
              <p>You have clicked enough times!</p>
              <button onClick={() => setClickCounterPopupVisible(false)}>Close</button>
            </div>
          )}
        </div>
      </Router>
    </ClickCounter>
  );
}

export default App;