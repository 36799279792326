import React, { useEffect, useState } from 'react';
import { Ad } from 'react-ad-manager';
import "../App.css";

const AdsenseContent = ({ adUnit, name, size }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(false);
  }, [adUnit]);

  const handleLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Ad
        adUnit={adUnit}
        name={name}
        size={size}
        onLoad={handleLoad} 
      />
      <div className={`ad-fade ${isLoaded ? 'fade-in' : 'fade-out'}`}>
      </div>
    </div>
  );
};

export default AdsenseContent;