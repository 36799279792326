import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import icon_1 from "../images/icon_1.png";
import icon_2 from "../images/icon_2.png";
import icon_3 from "../images/icon_3.png";
import icon_4 from "../images/icon_4.png";
import check from "../images/check.png";
import developer from "../images/developer.png";
import emoji1 from "../images/emoji1.png";
import emoji2 from "../images/emoji2.png";
import emoji3 from "../images/emoji3.png";
import appstore from "../images/appstore.png";
import googlestore from "../images/googlestore.png";
import Scrollacktop from "./Scrollacktop";
import { Helmet } from "react-helmet";
import SkeletonLoader from "./SkeletonLoader";
import AdsenseContent from "./AdsenseContent";
import getTopdata from "./Gameapps.json";
import Modal from "./Modal";
import Footer from "./Footer";
import lazy from "../images/lazy.png";
const GameDetail = () => {
  const [activeTab, setActiveTab] = useState("IOS");
  const [adLoaded, setAdLoaded] = useState(false);
  const { gameDetail } = useParams();
  const [game, setGame] = useState(null);
  const [games, setGames] = useState([]);
  const [error, setError] = useState(null);
  const [allGames, setAllGames] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("");
  const [modalContent, setModalContent] = useState({
    title: "",
    content: "",
    qrCode: "",
    storeLink: "",
  });

  useEffect(() => {
    const fetchGameData = async () => {
      try {
        const gameData = await import(`./JsonData/${gameDetail}.json`);
        setGame(gameData.game);
      } catch (err) {
        setError("Loading...");
      }
    };

    const fetchAllGamesData = async () => {
      try {
        const gameList = getTopdata.data.game;
        setAllGames(gameList);

        const gameEntries = gameList
          .slice(13, 39)
          .filter((game) => game.slug !== gameDetail);

        const gamePromises = gameEntries.map(async (game) => {
          const response = await import(`./JsonData/${game.slug}.json`);
          return response.game;
        });
        const recentGames = await Promise.all(gamePromises);
        setGames(recentGames);
      } catch (err) {
        console.error("Error loading all games data:", err);
        setError("Loading...");
      }
    };

    fetchGameData();
    fetchAllGamesData();

    const timer = setTimeout(() => {
      setAdLoaded(true);
    }, 100);
    return () => clearTimeout(timer);
  }, [gameDetail]);

  const recentGames1 = allGames.slice(0, 12);
  const onlineGames = allGames
    .slice(39, 77)
    .filter((game) => game.slug !== gameDetail);

  const handleQRCodeClick = (platform) => {
    if (platform === "App Store") {
      setModalContent({
        title: "Coming soon to the App Store",
        content: "Are you sure you want to continue?",
        qrCode: appQR,
        storeLink: game.app["App Store"].store_link,
      });
    } else if (platform === "Google Play") {
      setModalContent({
        title: "Coming soon to Google Play",
        content: "Are you sure you want to continue?",
        qrCode: googleQR,
        storeLink: game.app["Google Play"].store_link,
      });
    }
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  if (error || !game) {
    return (
      <main className="main main_error">
        <div>
          <h1>{error ? error : "Loading..."}</h1>
        </div>
      </main>
    );
  }

  const imagePath = `${process.env.PUBLIC_URL}/games/images/${game.image}`;
  const appQR =
    game.app && game.app["App Store"]
      ? `${process.env.PUBLIC_URL}/games/images/qr_code/${game.app["App Store"].app_qr_img}`
      : null;

  const googleQR =
    game.app && game.app["Google Play"]
      ? `${process.env.PUBLIC_URL}/games/images/qr_code/${game.app["Google Play"].app_qr_img}`
      : null;

  const renderStars = (rate) => {
    const maxStars = 5;
    const roundedRate = Math.round(rate * 2) / 2;
    const fullStars = Math.floor(roundedRate);
    const halfStar = roundedRate % 1 !== 0;

    let stars = [];
    for (let i = 0; i < fullStars; i++) {
      stars.push(<i key={i} className="fas fa-star rating"></i>);
    }
    if (halfStar) {
      stars.push(<i key="half" className="fas fa-star-half-alt rating"></i>);
    }
    for (let i = stars.length; i < maxStars; i++) {
      stars.push(<i key={i} className="far fa-star rating"></i>);
    }

    return stars;
  };

  const handleGameClick = (index) => {
    const nextIndex = index + 1 < games.length ? index + 1 : index;
    const nextGameSlug = games[nextIndex]?.slug;

    if (nextGameSlug) {
      window.location.href = `/online-game/${encodeURIComponent(nextGameSlug)}`;
    }
  };

  return (
    <main className="main backShare">
      <Helmet>
        <title>
          {game.name} | Top Game and App Reviews | Expert Reviews and
          Introductions
        </title>
      </Helmet>
      <br />
      <br />
      <br />
      <div className="container">
        <div className="row d-none d-md-flex cursor_point">
          {recentGames1.map((game) => (
            <div className="col-md-1 order-md-0 order-0" key={game.slug}>
              <div className="post-item mb-3">
                <div
                  onClick={() => {
                    window.location.href = `/online-game/${encodeURIComponent(
                      game.slug
                    )}`;
                  }}
                  className="portfolio-content h-100"
                >
                  <img
                    src={lazy}
                    data-src={`${process.env.PUBLIC_URL}/games/${game.image}`}
                    className="img-fluid image_size"
                    alt={game.name}
                    loading="lazy"
                    onLoad={(e) => {
                      e.target.src = e.target.dataset.src;
                    }}
                  />
                  <div className="overlay overlay_fontSize">{game.name}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-md-3 order-md-2 order-2 left-sidebar">
            {games.map((game) => (
              <div
                className="recent-posts-widget widget-item"
                key={game.slug}
                onClick={() => {
                  window.location.href = `/online-game/${encodeURIComponent(
                    game.slug
                  )}`;
                }}
              >
                <div className="post-item">
                  <img
                    src={lazy}
                    data-src={`${process.env.PUBLIC_URL}/games/images/${game.image}`}
                    className="game_images"
                    alt={game.name}
                    loading="lazy"
                    onLoad={(e) => {
                      e.target.src = e.target.dataset.src;
                    }}
                  />
                  <div className="game_name">
                    <h4 className="text-color">{game.name}</h4>
                    {renderStars(game.rating)}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="col-md-9 order-md-2 order-1">
            <div className="row mb-3">
              <div className="col-md-12 col_back">
                <div className="row mt-3">
                  <h3>{game.name}</h3>
                  <div className="col-lg-6">
                    <div className="add_shows">
                      <p className="Advertisement">Sponsored</p>
                      {!adLoaded ? (
                        <SkeletonLoader />
                      ) : (
                        <AdsenseContent
                          adUnit="/23178317433/9GH_display_2"
                          name="div-gpt-ad-1728898208741-0"
                          size={[
                            [320, 50],
                            [250, 250],
                            [300, 250],
                            [336, 280],
                            [300, 50],
                          ]}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="widgets-container_tw mt-1">
                      <div className="recent-posts-widget widget-item">
                        <div className="post-item2">
                          <img
                            src={imagePath}
                            alt="Game Thumbnail"
                            className="game_images_2"
                          />
                          <div className="game_name_2">
                            <h4>{game.name}</h4>
                            {renderStars(game.rating)} {game.rating}
                          </div>
                        </div>
                      </div>
                      <div className="row align-self-center">
                        <div className="col-md-6 icon-box">
                          <div className="row">
                            <div className="col-md-6 update_info_row">
                              <img src={icon_4} className="grid-box" />
                            </div>
                            <div className="col-md-6 update_info2_row">
                              <div className="game-title">Update</div>
                              <div className="game-subtitle">{game.Update}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 icon-box">
                          <div className="row">
                            <div className="col-md-6 update_info_row">
                              <img src={icon_1} className="grid-box" />
                            </div>
                            <div className="col-md-6 update_info2_row">
                              <div className="game-title">Developer</div>
                              <div className="game-subtitle">
                                {" "}
                                {game.Developer && game.Developer.length > 20
                                  ? `${game.Developer.substring(0, 20)}`
                                  : game.Developer}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row align-self-center mt-2">
                        <div className="col-md-6 icon-box">
                          <div className="row">
                            <div className="col-md-6 update_info_row">
                              <img src={icon_2} className="grid-box" />
                            </div>
                            <div className="col-md-6 update_info2_row">
                              <div className="game-title">OS</div>
                              <div className="game-subtitle">{game.OS}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 icon-box">
                          <div className="row">
                            <div className="col-md-6 update_info_row">
                              <img src={icon_3} className="grid-box" />
                            </div>
                            <div className="col-md-6 update_info2_row">
                              <div className="game-title">Category</div>
                              <div className="game-subtitle">
                                {game.Category}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row align-self-center mt-3">
                        <div className="col-md-12">
                          <h5 className="mt-2 main-title game_main-title">
                            What do you think of this app?
                          </h5>
                          <div
                            className="container section-title-2 aos-init aos-animate"
                            data-aos="fade-up game_main-title_2"
                          >
                            <h2
                              onClick={() => setBackgroundColor("DeepSkyBlue")}
                              style={{
                                cursor: "pointer",
                                backgroundColor:
                                  backgroundColor === "DeepSkyBlue"
                                    ? "DeepSkyBlue"
                                    : "#cbc3c3",
                                color: "black",
                                margin: "2px",
                              }}
                            >
                              Good <img src={emoji1} className="emoji" />
                            </h2>
                            <h2
                              onClick={() => setBackgroundColor("orange")}
                              style={{
                                cursor: "pointer",
                                backgroundColor:
                                  backgroundColor === "orange"
                                    ? "orange"
                                    : "#cbc3c3",
                                color: "black",
                                margin: "2px",
                              }}
                            >
                              General <img src={emoji2} className="emoji" />
                            </h2>
                            <h2
                              onClick={() => setBackgroundColor("red")}
                              style={{
                                cursor: "pointer",
                                backgroundColor:
                                  backgroundColor === "red" ? "red" : "#cbc3c3",
                                color: "black",
                                margin: "2px",
                              }}
                            >
                              Not Good <img src={emoji3} className="emoji" />
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-2 add_h">
                    <div className="add_shows">
                      <p className="Advertisement">Sponsored</p>
                      {!adLoaded ? (
                        <SkeletonLoader />
                      ) : (
                        <AdsenseContent
                          adUnit="/23178317433/9GH_display_3"
                          name="div-gpt-ad-1728898243701-0"
                          size={[
                            [320, 50],
                            [300, 50],
                            [250, 250],
                            [336, 280],
                            [300, 250],
                          ]}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 mt-2">
                    <div className="recent-posts-widget widget-item">
                      <div className="post-item2">
                        <img
                          src={check}
                          alt="Game Thumbnail"
                          className="game_images_3"
                        />
                        <div>
                          <h5>Good App Guaranteed:</h5>
                          <h6>
                            This app passed the security test for virus,malware
                            and other malicious attacks and doesn't containany
                            theats.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="content content-p border-box">
                      <h6 className="game_des"></h6>
                      {game.Description.map((section, index) => (
                        <div key={index} className="game_des">
                          {section.h2 && <h3>{section.h2}</h3>}
                          {section.p && <p className="p_tag_2">{section.p}</p>}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-12 qr_display">
                    <div className="widgets-container_tw mt-1">
                      {appQR && (
                        <div
                          className="recent-posts-widget widget-item"
                          onClick={() => handleQRCodeClick("App Store")}
                        >
                          <div className="post-item2 post_border">
                            <img src={appstore} alt="App Store QR Code" />
                            <div className="app_store">
                              <h6>Link provided by</h6>
                              <h4 className=" ">App Store</h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="widgets-container_tw mt-1">
                      {googleQR && (
                        <div
                          className="recent-posts-widget widget-item"
                          onClick={() => handleQRCodeClick("Google Play")}
                        >
                          <div className="post-item2 post_border">
                            <img src={googlestore} alt="Google Play QR Code" />
                            <div className="app_store">
                              <h6>Link provided by</h6>
                              <h4>Google Play</h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <Modal
                      isOpen={modalOpen}
                      onClose={closeModal}
                      title={modalContent.title}
                      content={modalContent.content}
                      qrCode={modalContent.qrCode}
                      storeLink={modalContent.storeLink}
                    />
                  </div>

                  <div className="col-md-12 mt-5">
                    <ul className="nav nav-tabs">
                      <li
                        className={`nav-item ${
                          activeTab === "IOS" ? "active" : ""
                        }`}
                      >
                        <a
                          className="nav-link"
                          onClick={() => setActiveTab("IOS")}
                          href="#!"
                        >
                          iOS
                        </a>
                      </li>
                      <li
                        className={`nav-item ${
                          activeTab === "Android" ? "active" : ""
                        }`}
                      >
                        <a
                          className="nav-link"
                          onClick={() => setActiveTab("Android")}
                          href="#!"
                        >
                          Android
                        </a>
                      </li>
                    </ul>

                    <div className="tab-content">
                      <div
                        className={`tab-pane fade ${
                          activeTab === "IOS" ? "show active" : ""
                        }`}
                        id="IOS"
                      >
                        <table className="table table-bordered table-striped">
                          <tbody>
                            {game.app_info.IOS &&
                            game.app_info.IOS.length > 0 ? (
                              game.app_info.IOS.map((info, index) => (
                                <tr key={index}>
                                  <td>{info.label}</td>
                                  <td>{info.text}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="2" className="text-center">
                                  Data not found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>

                      <div
                        className={`tab-pane fade ${
                          activeTab === "Android" ? "show active" : ""
                        }`}
                        id="Android"
                      >
                        <table className="table table-bordered table-striped">
                          <tbody>
                            {game.app_info.Android &&
                            game.app_info.Android.length > 0 ? (
                              game.app_info.Android.map((info, index) => (
                                <tr key={index}>
                                  <td>{info.label}</td>
                                  <td>{info.text}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="2" className="text-center">
                                  Data not found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mt-2 add_h">
                    <div className="add_shows">
                      <p className="Advertisement">Sponsored</p>
                      {!adLoaded ? (
                        <SkeletonLoader />
                      ) : (
                        <AdsenseContent
                          adUnit="/23178317433/9GH_display_4"
                          name="div-gpt-ad-1728898278519-0"
                          size={[
                            [300, 250],
                            [300, 50],
                            [250, 250],
                            [336, 280],
                            [320, 50],
                          ]}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 mt-5">
                    <h3 className="online_game">Online Games</h3>
                    <div className="row share_section cursor_point">
                      {onlineGames.map((game) => (
                        <div className="col-md-2 mt-2 mb-2" key={game.slug}>
                          <div className="post-item">
                            <div
                              onClick={() => {
                                window.location.href = `/online-game/${encodeURIComponent(
                                  game.slug
                                )}`;
                              }}
                              className="portfolio-content games_list h-100"
                            >
                              <img
                                src={lazy}
                                data-src={`${process.env.PUBLIC_URL}/games/${game.image}`}
                                className="img-fluid image_size"
                                alt={game.name}
                                loading="lazy"
                                onLoad={(e) => {
                                  e.target.src = e.target.dataset.src;
                                }}
                              />
                              <div className="overlay_online_game">
                                {game.name}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Scrollacktop />
      <br />
      <Footer />
    </main>
  );
};

export default GameDetail;
