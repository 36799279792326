import React from 'react';
import "../App.css";

const SkeletonLoader = () => {
  return (
    <p className="skeleton-loader  loader skeleton-ad p_tg text-center text-16">
    </p>
  );
};

export default SkeletonLoader;
